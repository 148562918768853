import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

import Grid from '@material-ui/core/Grid';

import Title from '../../1atoms/title/title.js';
import BarBtn from '../../1atoms/button/button.js';
import Cards from '../../2molecules/card/card.js';

// Criação do estilo
const styles = {
    pad_rows:{
//        paddingTop: '15px'
    }, 
    list_cards: {
//        paddingTop: '10px',
//        paddingBottom: '20px',
        backgroundColor: grey[50],
    },
    btn_post:{
        display: 'block',
        textAlign: 'center',
        paddingTop: '10px',
        paddingBottom: '10px',
    }
};

//                    <Cards desafio={desafio} desafioid={desafioid} cards_size={props.cards_size}/>


function ListCards (props) {   
    const { classes, onPress_sup, onPress_take, onPress_soso, onPress_comm } = props;
    const title = "Produtos já postados:"
    const btn1 = "Postar um produto";
    var cardss = [];
    var product, qtde, user_prod_interactions;
    (props.products.length !== 0) ? qtde = props.products.length : qtde = 1;
    for (var i = 0; i < qtde; i++) {
        if ((props.products !== '')||(props.products !== undefined )){
            product = props.products[i];
            user_prod_interactions ='';
            for (var j = 0; j < props.user_prod_interactions.length; j++) {
                if (props.user_prod_interactions[j].id === product.id){
                    user_prod_interactions = props.user_prod_interactions[j];
                }
            }
        } else {
            product = '';
            user_prod_interactions ='';
        }
        cardss.push( 
            <Grid key={i} item xs={12} sm={6} md={4}>
                <Grid container justify="center" className={classes.pad_rows}>
                    <Cards product={product} user_prod_interactions={user_prod_interactions} onPress_take={onPress_take} onPress_soso={onPress_soso} onPress_comm={onPress_comm} uid={props.uid}/>
                </Grid>
            </Grid>
        );
    }
    
    
    return (
        <div className={classes.list_cards}>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={8}>
                    <Title title={title}/>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.btn_post}>
                    <BarBtn btn_name={btn1} onPress_btn={onPress_sup} className={classes.btn_post}/>
                </Grid>
                {cardss}
            </Grid>
        </div>
    );
}

ListCards.propTypes = {
  classes: PropTypes.object.isRequired,
};

// Colocação do estilo no componente
export default withStyles(styles)(ListCards);