import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';


// Criação do estilo
const styles = {
  root_title: {
      flexGrow: 1,
  },
    font_title: {
        color: green[900],
        fontWeight: 'bold',
        textAlign: 'center',
        paddingTop: '10px',
        marginBottom: '0px',
    },
    title_card:{
        color: green[700],
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 20,
        marginBottom: '0px',
        //height: 60,
    },
    title_description:{
        color: green[500],
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 12,
        lineHeight: '1.1',
        marginBottom: '0px',
        display: 'flex',
        alignItems: 'center',
    },
    title_type:{
        color: green[500],
        fontWeight: 'bold',
        textAlign: 'left',
        fontSize: 12,
        lineHeight: '1.1',
        minHeight: '26px',
        marginBottom: '0px',
        display: 'flex',
        alignItems: 'center',
        paddingTop: 3,
    },
    comments:{
        color: grey[500],
        fontWeight: 'bold',
        textAlign: 'left',
        fontSize: 12,
        lineHeight: '1.1',
        paddingBottom: 5,
        marginBottom: '0px',
        paddingRight: 10,
        paddingLeft: 20,
    },
    title_description2:{
        color: green[500],
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 12,
        lineHeight: '1.2',
        marginBottom: '0px',
        display: 'flex',
        alignItems: 'center',
    },
    helper_input:{
        margin: '8px 12px 0',
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
        testAlign: 'left',
        minHeight: '1em',
        lineHeight: '1em',
    }
};

function Title (props) {
    const { classes } = props;
    var card_css = classes.font_title;
    var variant="h5";
    var component="h2"
    
    if (props.titlecss === 'card_title'){ card_css = classes.title_card; }
    if (props.titlecss === 'card_description'){ card_css = classes.title_description; }
    if (props.titlecss === 'card_type'){ card_css = classes.title_type; }
    if (props.titlecss === 'comments'){ card_css = classes.comments; }
    if (props.titlecss === 'card_description2'){ card_css = classes.title_description2; }
    if (props.titlecss === 'helper_input'){ card_css = classes.helper_input; }
    
    return (
        <div className={classes.root_title}>
            <Typography className={card_css} variant={variant} component={component} gutterBottom>{props.title}</Typography>
        </div>
    );
}

Title.propTypes = {
  classes: PropTypes.object.isRequired,
};

// Colocação do estilo no componente
export default withStyles(styles)(Title);