import React from 'react';
import * as firebase from 'firebase';

import BarUp from '../../3organisms/bar/bar.js'
import ModalWrapped from '../../4ecosystems/modal/modal.js'
import Dial from '../../4ecosystems/dialog/dialog.js';

class Bar extends React.Component {   

    constructor(){
        super();
        this.state={
            open: false,
            msg: 'A senha deve ter no mínimo 6 caracteres',
            msg_er: ' ',
            password: '',
            loginname: '',
            opendial: false,
            opendialmsg: '',
        };

        this.handleLogin = this.handleLogin.bind(this);
        this.handleSignup = this.handleSignup.bind(this);
        this.handleLogsign = this.handleLogsign.bind(this);
        this.handlePassword = this.handlePassword.bind(this);

    }

    handleOpenlogin = () => { this.setState({ open: true, action: 'login' });    };

    handleOpensign = () => { this.setState({ open: true, action: 'signup' });    };

    handleClose = () => { this.setState({ open: false, msg_er: '' });     };

    handlePassword = (event) => { this.setState({ password: event.target.value });  };

    handleLoginname = (event) => { this.setState({ loginname : event.target.value }); };

    handleCloseDial = () => {
        this.setState({ opendial: false });
        
        if (this.state.opendialmsg === 'A sua resposta foi enviada, obrigado!'){
            this.setState({ page: 1});
        }
        
    };

    handleSignup(){

        if( (this.state.loginname !== '') && (this.state.password !== '')){
            const email = this.state.loginname;
            const pass = this.state.password;

            function createUser(email, pass){
                const errors = firebase.auth().createUserWithEmailAndPassword(email, pass)
                .then(function() {

                    var user = firebase.auth().currentUser; // Getting info of the user 
                    user.sendEmailVerification().then(function() { // Send verfication email
                        console.log('Email sent'); // Email sent.
                    });

                    console.log('User created');
                    logOut();
                    const errors = '';
                    return errors;

                }).catch(function(error) { // Handle Errors here.
                    const errors = error.code;
                    return errors;
                });
                return errors;
            }

        createUser(email, pass).then( errors => {
            this.setState({ msg_er: errors });
            console.log('errors ' + errors);
            if (errors === ''){
                this.setState({ opendial: true, opendialmsg: 'O e-mail de verificação foi enviado, por favor, verifique a sua caixa de emails.' });
                this.setState({ open: false });
            }
        });                
            
        function logOut(){ firebase.auth().signOut(); }
            
        } else {
            this.setState({ msg_er: 'É necessário preencher o login e a senha do usuário.'})
        }

    }

    handleLogsign(){
        if(this.state.action==='login'){
            this.handleLogin();
        }
        if(this.state.action==='signup'){
            this.handleSignup();
        }
    }

    handleLogin(){
        if( (this.state.loginname !== '') && (this.state.password !== '')){
            const email = this.state.loginname;
            const pass = this.state.password;

        var db = firebase.firestore(); // Connecting to Firestore

        // Signing in and checking if the email was verified

        firebase.auth().signInWithEmailAndPassword(email, pass)
        .then( function (firebaseUser) {
            const user = firebase.auth().currentUser;            
            const emailverif = user.emailVerified;
            return emailverif;
        }).then( async function (emailverif) { // Verificação do email
            var msg;
            if (emailverif){ // Login com sucesso
                const usersRef = db.collection("users");
                const user = firebase.auth().currentUser;
                const user_email = user.email;
                const uid = user.uid;

                const currentTime = new Date();
                var userRef2 = usersRef.doc(uid);
                userRef2.set({
                    user: uid,
                    last_login: currentTime,
                    user_email: user_email,
                    active: true
                }) 
                .then(function() { 
                    console.log("Document successfully created!"); 
                })
                .catch(function(error) { console.error("Error creating document: ", error); });
                msg = '';
            } else { // Email não verificado
                const user = firebase.auth().currentUser; 
                 msg = await user.sendEmailVerification().then(async function() { // Email sent.
                    var msg =  await firebase.auth().signOut().then(async function() {
                        msg = 'Email sent';
                        return msg;
                    });
                    return msg;
                });
                return msg;
            }
        }).then( msg => {
            if (msg === 'Email sent'){
                this.setState({ opendial: true, opendialmsg: 'O endereço de email informado ainda não foi verificado. O email de verificação foi reenviado, por favor, verifique a sua caixa de entrada.' });
            }
        }).catch(function(error) { // An error happened.
            const msg = error.code;
            return msg;
        }).then( msg => {
            this.setState({ msg_er: msg });
            if((msg==='') || (msg === undefined) ) { this.setState({ open: false })};
        });
    }

    }

    render(){
    function logOut(){ firebase.auth().signOut(); }
    return (
        <div>
            <BarUp onPresslogout={logOut} uid={this.props.uid} onPress={this.handleOpenlogin} onPress_sup={this.handleOpensign}/>
            <Dial opendial={this.state.opendial} onPressedcl={this.handleCloseDial} opendialmsg={this.state.opendialmsg} />
            <ModalWrapped action={this.state.action} onPressed={this.handleLogsign} onChanged={this.handlePassword} onChanged2={this.handleLoginname} open={this.state.open} onClose2={this.handleClose} msg_er={this.state.msg_er}/>
        </div>
    );
    }
}


// Colocação do estilo no componente
export default Bar;