import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

import BarBtn from '../../1atoms/button/button.js'

import BarSignlogin from '../../2molecules/login_signup/bar_sign_login.js'

import LogoImg from '../../media/escrito_fb.jpg'

// Criação do estilo
const styles = {
    root: {
        flexGrow: 1,
    },
    tool_bar2:{
        minHeight: 50,
        backgroundColor: grey[50],
    },
    imglogo:{
        margin: 'auto',
        display: 'block',
    },
    logs:{
        margin: 'auto',
        paddingTop: '5px',
        textAlign: 'center',
    },
    
    tool_bar:{
        minHeight: 100,
        backgroundColor: grey[50],
    },
    imglogo2:{
    },
    logs2:{
        margin: 'auto',
        paddingTop: '10px',
        textAlign: 'right',
    }
};

function BarUp (props) {   
    const { onPress_sup, onPresslogout, onPress, classes } = props;    
    const btn1 = 'Logout';
    return (
        <div className={classes.root}>
            <Hidden smUp>
                <AppBar position="static" >
                    <Toolbar className={classes.tool_bar}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <img  src={LogoImg} className={classes.imglogo} alt="Wontgivup"/>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.logs}>
                                    {(props.uid === null) && <BarSignlogin onPress={onPress} onPress_sup={onPress_sup}/>}
                                    {(props.uid !== null) && <BarBtn btn_name={btn1} onPress_btn={onPresslogout} />}
                                 </div>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>       
            </Hidden>
                                     
            <Hidden only="xs">
                <AppBar position="static" >
                    <Toolbar className={classes.tool_bar2}>
                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                                <img  src={LogoImg} className={classes.imglogo2} alt="Wontgivup"/>
                            </Grid>
                            <Grid item xs={6}>
                                <div className={classes.logs2}>
                                    {(props.uid === null) && <BarSignlogin onPress={onPress} onPress_sup={onPress_sup}/>}
                                    {(props.uid !== null) && <BarBtn btn_name={btn1} onPress_btn={onPresslogout} />}
                                 </div>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Hidden>
        </div>
    );
}

BarUp.propTypes = {
  classes: PropTypes.object.isRequired,
};

// Colocação do estilo no componente
export default withStyles(styles)(BarUp);