import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';

import BarBtn from '../../1atoms/button/button.js';

// Criação do estilo
const styles = {
  text_or:{
      display: 'inline-block',
      verticalAlign: 'bottom',
      paddingBottom: '7px',
      color: green[700],
//      fontSize: 28,
      paddingLeft: '4px',
      paddingRight: '4px',
  },
};

function BarSignlogin (props) {   
    const { onPress_sup, onPress, classes } = props;
    const btn1 = 'Signup';
    const btn2 = 'Login';
    return (
        <div>
            <BarBtn btn_name={btn1} onPress_btn={onPress_sup} />
            <span className={classes.text_or}>ou</span>
            <BarBtn btn_name={btn2} onPress_btn={onPress} />
        </div>
    );
}

BarSignlogin.propTypes = {
  classes: PropTypes.object.isRequired,
};

// Colocação do estilo no componente
export default withStyles(styles)(BarSignlogin);