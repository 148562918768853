import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import green from '@material-ui/core/colors/green';
import Title from '../../1atoms/title/title.js';
import Grid from '@material-ui/core/Grid';
import BarBtn from '../../1atoms/button/button.js';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
    paper: {
        position: 'absolute',
        maxWidth: 320,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: 0,
        maxHeight: '85vh',
        overflowY:'auto',
    },  
    
  cssLabel: {
    '&$cssFocused': {
      color: green[500],
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: green[500],
    },
  },
    
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: green[500],
    },
  },
  notchedOutline: {},
    title:{
        fontSize: 28,
        padding: 5,
    },
    img:{
        margin: 'auto',
        display: 'block',
        width: '100%',
    },
    card_btn:{
    textAlign: 'center',
    paddingTop: 5,
    paddingBottom: 10,
  },
    comments:{
        paddingBottom:5,
        paddingTop: 10,
    },
    comments2a:{
        paddingTop: 0,
        paddingBottom: 0,
        textAlign: 'center',
    },
    comments2b:{
        paddingTop: 0,
        paddingBottom: 10,
        textAlign: 'right',
        paddingRight: 4,
    },
    comments2c:{
        marginTop: 0,
        marginBottom: 10,
    },
    comments2d:{
        paddingTop: 0,
        paddingBottom: 0,
        textAlign: 'center',
        paddingLeft: 5,
        paddingRight: 5,
    },
    card_divider:{
        marginTop: 0,
        marginBottom: 10,
    },
});

class ModalProduct extends React.Component {

  render() {
    const {onChange_comm, onPressed_savecomm, onPressed_take, onPressed_soso, onPressed_comm, onClose2, classes } = this.props;
    var open_modal = false;
    var commentss = [];
    if(this.props.open === true) { open_modal = true; }
    var btn_name= 'Comentar';
    
    if ((this.props.comments_vector !== '')&&(this.props.comments_vector !== undefined )){
        if(this.props.comments_vector.length >0){
            commentss.push( 
                <Grid key={200} item xs={12} >
                    <Title title={"Comentários"} titlecss={"comments"}/>
                </Grid>
            );
            for (var i = 0; i < this.props.comments_vector.length; i++) {
                var comentss2 = this.props.comments_vector[i];
                var data;
                if (comentss2.just_added){
                    data = comentss2.creation_time;
                } else {
                    data = comentss2.creation_time.toDate();
                }
                const dia  = data.getDate().toString();
                const diaF = (dia.length === 1) ? '0'+dia : dia;
                const mes  = (data.getMonth()+1).toString(); //+1 pois no getMonth Janeiro começa com zero.
                const mesF = (mes.length === 1) ? '0'+mes : mes;
                const anoF = data.getFullYear();
                const dataF =  diaF+"/"+mesF+"/"+anoF;
                
                commentss.push( 
                    <Grid key={i} item xs={12} >
                        <Divider  />
                        <Grid container justify="center" className={classes.comments}>
                            <Title title={"" + dataF + ": " + comentss2.comment} titlecss={"comments"}/>
                        </Grid>
                    </Grid>
                );
            }
        }
    }
      
      return (
      <div>
        <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open_modal} onClose={onClose2} >
          <div style={getModalStyle()} className={classes.paper}>
                <img  src={this.props.product_img} className={classes.img} alt="Imagem do produto"/>
                <Grid container spacing={0}>
                    <Grid item xs={12} className={classes.title}>
                        <Title title={this.props.title} titlecss={this.props.card_title}/>
                    </Grid>
                    <Grid item xs={12} className={classes.title}>
                        <Title title={this.props.description} titlecss={'card_description2'}/>
                    </Grid>
                    <Grid item xs={7} className={classes.title}>
                        <Title title={this.props.type} titlecss={this.props.card_type}/>
                    </Grid>
                    <Grid item xs={5} className={classes.title}>
                        <Title title={this.props.value} titlecss={this.props.card_type}/>
                    </Grid>
                    <Grid item xs={4} className={classes.card_btn}>
                        <Divider  variant="middle" className={classes.card_divider}/>
                        <BarBtn btn_type={this.props.btn1} onPress_btn={onPressed_take} btn_pressed={this.props.btn_pressed1} btn_value={this.props.btn1_value} />
                    </Grid>
                    <Grid item xs={4} className={classes.card_btn}>
                        <Divider  variant="middle" className={classes.card_divider}/>
                        <BarBtn btn_type={this.props.btn2} onPress_btn={onPressed_soso} btn_pressed={this.props.btn_pressed2} btn_value={this.props.btn2_value}/>
                    </Grid>
                    <Grid item xs={4} className={classes.card_btn}>
                        <Divider  variant="middle" className={classes.card_divider}/>
                        <BarBtn btn_type={this.props.btn3} onPress_btn={onPressed_comm} btn_pressed={this.props.comments} btn_value={this.props.btn3_value}/>
                    </Grid>
                    {commentss}

{(this.props.commenting) &&
                    <Grid item xs={12} className={classes.comments2a}>
                            <Divider className={classes.comments2c} />
                    </Grid>
}
{(this.props.commenting) &&
                    <Grid item xs={12} className={classes.comments2d}>

                          <TextField
                            onChange={onChange_comm}
                            multiline
                            rows="4"
                            helperText={this.props.chars_left + " caracteres restantes"}
                            InputLabelProps={{
                              classes: {
                                root: classes.cssLabel,
                                focused: classes.cssFocused,
                              },
                            }}
                            className={classes.margin }
                            InputProps={{
                              classes: {
                                root: classes.cssOutlinedInput,
                                focused: classes.cssFocused,
                                notchedOutline: classes.notchedOutline,
                              },
                            }}
                            value={this.props.comment_written}
                            fullWidth
                            label="Comentários"
                            variant="outlined"
                            id="comments-outlined-input"
                          />
                    </Grid>
 }
{(this.props.commenting) &&
                    <Grid item xs={12} className={classes.comments2b}>
                        <BarBtn btn_name={btn_name} onPress_btn={onPressed_savecomm} />                                  
                    </Grid>
}
                </Grid>
            <ModalWrapped />
          </div>
        </Modal>
      </div>
    );
  }
}

ModalProduct.propTypes = {
  classes: PropTypes.object.isRequired,
};

// We need an intermediary variable for handling the recursive nesting.
const ModalWrapped = withStyles(styles)(ModalProduct);

export default ModalWrapped;