import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import grey from '@material-ui/core/colors/grey';
import pink from '@material-ui/core/colors/pink';
import yellow from '@material-ui/core/colors/yellow';
import LogoImg from '../../media/escrito_fb.jpg'

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
    paper: {
        position: 'absolute',
        maxWidth: 320,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },  
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
  cssLabel: {
    '&$cssFocused': {
      color: grey[900],
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: grey[900],
    },
  },
    submit:{
      fontSize: 12,
    },
    errors:{
      paddingTop: 10,
      fontSize: 14,
    },
    title:{
        fontSize: 28,
    },
    icone:{
        fontFamily: 'Tangerine',
        color: pink[900],
        fontWeight: 'bold',
        fontSize: 34,
        marginBottom: '6px',
        backgroundColor: yellow[100],
},    
    imglogo:{
        margin: 'auto',
        display: 'block',
    },
});

class ModalLogin extends React.Component {

  render() {
    const {onChanged2, onChanged, onPressed, onClose2, classes } = this.props;
    var action_name;
    var msg_er = [];
    var open_modal = false;
    if(this.props.open === true) { open_modal = true; }
    if(this.props.action==='signup'){
        action_name = 'Sign up';
    }
    if(this.props.action==='login'){
        action_name = 'Sign in';
    }
    if(this.props.msg_er === 'auth/invalid-email'){
        msg_er.push( 
                <Typography key="1" align="center" color="error" variant="h6" className={classes.errors}>
                  O email inserido é invalido.
                </Typography>
        );
    }
    if(this.props.msg_er === 'auth/wrong-password'){
        msg_er.push( 
                <Typography key="2" align="center" color="error" variant="h6" className={classes.errors}>
                  A senha não está correta.
                </Typography>
        );
    }
    if(this.props.msg_er === 'auth/invalid-password'){
        msg_er.push( 
                <Typography key="3" align="center" color="error" variant="h6" className={classes.errors}>
                  Essa senha não é válida.
                </Typography>
        );
    }
    if(this.props.msg_er === 'auth/weak-password'){
        msg_er.push( 
                <Typography key="4" align="center" color="error" variant="h6" className={classes.errors}>
                  A senha deve ter no mínimo 6 caracteres.
                </Typography>
        );
    }
    if(this.props.msg_er === 'auth/user-not-found'){
        msg_er.push( 
                <Typography key="5" align="center" color="error" variant="h6" className={classes.errors}>
                  Esse usuário não foi encontrado.
                </Typography>
        );
    }
    if(this.props.msg_er === 'É necessário preencher o login e a senha do usuário.'){
        msg_er.push( 
                <Typography key="6" align="center" color="error" variant="h6" className={classes.errors}>
                  É necessário preencher o login e a senha do usuário.
                </Typography>
        );
    }
    if(this.props.msg_er === 'auth/email-already-in-use'){
        msg_er.push( 
                <Typography key="7" align="center" color="error" variant="h6" className={classes.errors}>
                  Esse email já está cadastrado.
                </Typography>
        );
    }
      
      return (
      <div>
        <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open_modal} onClose={onClose2} >
          <div style={getModalStyle()} className={classes.paper}>
            <img  src={LogoImg} className={classes.imglogo} alt="Wontgivup"/>
            <Typography component="h1" variant="display1" className={classes.title}>
              {action_name}
            </Typography>
            <form className={classes.form}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email" classes={{ root: classes.cssLabel, focused: classes.cssFocused,}}>
                    Email Address
                </InputLabel>
                <Input id="email" name="email" autoComplete="email" autoFocus classes={{ underline: classes.cssUnderline,}} onChange={onChanged2}/>
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password" classes={{ root: classes.cssLabel, focused: classes.cssFocused,}}>Password</InputLabel>
                <Input name="password" type="password" id="password" autoComplete="current-password" classes={{ underline: classes.cssUnderline,}}  onChange={onChanged}/>
              </FormControl>
              <Button onClick={onPressed} fullWidth variant="contained" color="default" className={classes.submit}>
                {action_name}
              </Button>
                {msg_er}
            </form>

            <ModalWrapped />
          </div>
        </Modal>
      </div>
    );
  }
}

ModalLogin.propTypes = {
  classes: PropTypes.object.isRequired,
};

// We need an intermediary variable for handling the recursive nesting.
const ModalWrapped = withStyles(styles)(ModalLogin);

export default ModalWrapped;