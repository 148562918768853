import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import Button from '@material-ui/core/Button';
import LocalAtm from '@material-ui/icons/LocalAtm';
import ThumbsUpDown from '@material-ui/icons/ThumbsUpDown';
import Comment from '@material-ui/icons/Comment';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';

// Criação do estilo
const styles = {
    root:{
      display: 'inline-block',
      paddingTop: 0,
      paddingBottom: 0,
    },
    btn: {
      textTransform: 'none',
      fontWeight: 'bold',
      color: green[900],
//      fontSize: 28,
      borderColor: green[700],
      border: '1px solid',
      marginLeft: '4px',
      marginRight: '4px',
  },
    icon_style: {
      fontSize: 40,  
    },
    btn_icons:{
      textTransform: 'none',
      fontWeight: 'bold',
      color: grey[300],
//      fontSize: 28,
      borderColor: grey[50],
      border: '1px solid',
      marginLeft: '4px',
      marginRight: '4px',
      padding: 0,
    },
    btn_icons_pressed:{
      textTransform: 'none',
      fontWeight: 'bold',
      color: green[500],
//      fontSize: 28,
      borderColor: grey[50],
      border: '1px solid',
      marginLeft: '4px',
      marginRight: '4px',
      padding: 0,
    },
  badge: {
    top: '-10%',
    right: -8,
    backgroundColor: green[500],
    // The border color match the background color.
//    border: '2px solid',
//    borderColor: grey[50],
  },
};

function BarBtn (props) {   
    const { onPress_btn, classes } = props;
    var btn_name = props.btn_name;
    var btn_css = classes.btn;
    if ((props.btn_type !== "") && (props.btn_type !== undefined)){ btn_name=''; btn_css = classes.btn_icons;}
    if ( props.btn_pressed ){ btn_css = classes.btn_icons_pressed; }
//    if (props.btn_type === "soso"){}
//    if (props.btn_type === "comm"){}
    var disabled_btn=false;
    if (props.disabled_btn!==undefined){ disabled_btn = true }
    return (
        <div className={classes.root}>
            <Button onClick={onPress_btn} size="medium" className={btn_css} disabled={disabled_btn}>
                {btn_name}
{ ((props.btn_type === "take") && ((props.btn_value !== undefined) &&  (props.btn_value !== 0))) &&
      <Tooltip title="Cala a boca e pega meu dinheiro! (Botão indicando que você gostou muito da ideia)" aria-label="Cala a boca e pega meu dinheiro! (Botão indicando que você gostou muito do produto)">
          <Badge badgeContent={props.btn_value} max={999} color="primary" classes={{ badge: classes.badge }}>
                <LocalAtm className={classes.icon_style} />
          </Badge>
      </Tooltip>
}
{ ((props.btn_type === "take") && ((props.btn_value === undefined) || (props.btn_value === 0) )) &&
      <Tooltip title="Cala a boca e pega meu dinheiro! (Botão indicando que você gostou muito da ideia)" aria-label="Cala a boca e pega meu dinheiro! (Botão indicando que você gostou muito do produto)">
            <LocalAtm className={classes.icon_style} />
      </Tooltip>
}

{ ((props.btn_type === "soso") && ((props.btn_value !== undefined) &&  (props.btn_value !== 0))) &&
      <Tooltip title="Achei interessante! (Botão indicando que você vê um potencial limitado na ideia)" aria-label="Achei interessante! (Botão indicando que você vê um potencial limitado na ideia)">
          <Badge badgeContent={props.btn_value} max={999} color="primary" classes={{ badge: classes.badge }}>
                <ThumbsUpDown className={classes.icon_style} />
          </Badge>
      </Tooltip>
}
{ ((props.btn_type === "soso") && ((props.btn_value === undefined) || (props.btn_value === 0) )) &&
      <Tooltip title="Achei interessante! (Botão indicando que você vê um potencial limitado na ideia)" aria-label="Achei interessante! (Botão indicando que você vê um potencial limitado na ideia)">
            <ThumbsUpDown className={classes.icon_style} />
      </Tooltip>
}


{ ((props.btn_type === "comm") && ((props.btn_value !== undefined) &&  (props.btn_value !== 0))) &&
      <Tooltip title="Comentários" aria-label="Comentários">
          <Badge badgeContent={props.btn_value} max={999} color="primary" classes={{ badge: classes.badge }}>
                <Comment className={classes.icon_style} />
          </Badge>
      </Tooltip>
}
{ ((props.btn_type === "comm") && ((props.btn_value === undefined) || (props.btn_value === 0) )) &&
      <Tooltip title="Comentar" aria-label="Comentar">
            <Comment className={classes.icon_style} />
      </Tooltip>
}
            </Button>
        </div>
    );
}

BarBtn.propTypes = {
  classes: PropTypes.object.isRequired,
};

// Colocação do estilo no componente
export default withStyles(styles)(BarBtn);