import firebase from 'firebase/app';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';

import Title from '../../1atoms/title/title.js';
import BarBtn from '../../1atoms/button/button.js';
import Dial from '../../4ecosystems/dialog/dialog.js';
import ModalWrapped from '../../4ecosystems/modal_product/modal_product.js';


// Criação do estilo
const styles = {
  company:{
    display: 'flex',
    alignItems: 'flex-end'
  },
    right_col:{
        paddingTop: '10px'
    },
  card_content:{
    paddingTop: '0px',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  card: {
    height: 340,
    width: 310,
    marginBottom: '20px',
  },
  media: {
    height: 140,
    width: 340,
    '&:hover': {
    cursor: 'pointer',
  },
  },
  card_title:{
    height: 60,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
    cursor: 'pointer',
  },
  },
  card_description:{
    height: 40,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
    cursor: 'pointer',
  },
  },
    card_btn:{
    textAlign: 'center',
    paddingTop: 3,
    paddingBottom: 10,
  },
  card_type:{
    paddingTop: 3,
    paddingBottom: 0,
    '&:hover': {
    cursor: 'pointer',
  },
  },
    card_divider:{
        marginTop: 0,
        marginBottom: 10,
    },
};


class Cards extends Component {   
    
    constructor(){
        super();
        this.state={
            challenges: '',
            opendial: false,
            opendialmsg: '',
            btn_pressed1: false,
            btn_pressed2: false,
            comments: false,
            btn1_value: 0,
            btn2_value: 0,
            btn3_value: 0,
            open: false,
            comment: '',
            comments_vector:'',
            commenting: false,
            chars_left: 250,
        }
        this.handleClick_take=this.handleClick_take.bind(this);
        this.handleSavecomm=this.handleSavecomm.bind(this);
    }
    
    handleOpen = () => { this.setState({ open: true });    };

    handleClose = () => { this.setState({ open: false, commenting: false });     };

    handleCommchange = (event) => { 
        const charCount = event.target.value.length;
        const charLeft = 250 - charCount;
        if (charLeft >= 0) { this.setState({ comment: event.target.value, chars_left: charLeft }); }
    }
    
    async componentWillMount(){
        if(this.props.product !== undefined){
            this.setState({
                btn1_value: this.props.product.btn0,
                btn2_value: this.props.product.btn1,
                btn3_value: this.props.product.btn3,
            });
            
//        A variável this.props.user_prod_interactions.status_btn indica se o botão foi ou não clicado pelo usuário e essa varia´vel pode ter os seguintes valores
//        btna ou undefined -> ninguém clicou
//        btnb -> clicaram no btn1
//        btnc -> clicaram no btn2
//        Para indicar se houve clique nos comentários existe a variável comments
            if(this.props.user_prod_interactions !== undefined){
                if(this.props.user_prod_interactions.status_btn === 'btnb'){ this.setState({ btn_pressed1: true });}
                if(this.props.user_prod_interactions.status_btn === 'btnc'){ this.setState({ btn_pressed2: true });}
                if(this.props.user_prod_interactions.comments ){ this.setState({ comments: true });} 
            }
            
            var db = firebase.firestore(); // Connecting to Firestore        
            var comments_vector = [];
            comments_vector = await db.collection("products").doc(this.props.product.id).collection("comments").orderBy("creation_time").get()
                .then(function(querySnapshot) {
                    querySnapshot.forEach(function(doc) {
                        comments_vector.push(doc.data());
                        comments_vector[comments_vector.length - 1].id = doc.id;
                    });
                    return comments_vector;
                })
                .catch(function(error) {
                    console.log("Error getting documents: ", error);
            });
            this.setState({ comments_vector: comments_vector });
        }
    }
    
    // Operações ao se clicar no primeiro botão:
    // 1 - Verifica se está logado, se não estiver manda um aviso
    // 2 - Estando logado:
    // 3 - Atualiza o botão e salva essa atualização no banco de dados
    
    handleClick_take = () => {
        if(this.props.uid===null){
            this.setState({ opendial: true, opendialmsg: 'Você precisa estar logado para isso.' });
        } else {
            
            var db = firebase.firestore(); // Connecting to Firestore
            var btn1_value = this.state.btn1_value;
            var btn2_value = this.state.btn2_value;

            if((this.state.btn_pressed1===false) && ((this.state.btn_pressed2===false))){
                this.setState({ btn_pressed1: true, btn1_value: btn1_value + 1 });
                db.collection("products").doc(this.props.product.id).set({ btn0: btn1_value + 1}, { merge: true });
                db.collection("users").doc(this.props.uid).collection("user_prods_interactions").doc(this.props.product.id).set({ status_btn: 'btnb' }, { merge: true });
            }
            if((this.state.btn_pressed1===true) && ((this.state.btn_pressed2===false))){
                this.setState({ btn_pressed1: false, btn1_value: btn1_value - 1 });
                db.collection("products").doc(this.props.product.id).set({ btn0: btn1_value - 1 }, { merge: true });
                db.collection("users").doc(this.props.uid).collection("user_prods_interactions").doc(this.props.product.id).set({ status_btn: 'btna' }, { merge: true });
            }
            if((this.state.btn_pressed1===false) && ((this.state.btn_pressed2===true))){
                this.setState({ 
                    btn_pressed1: true, 
                    btn_pressed2: false,
                    btn1_value: btn1_value + 1,
                    btn2_value: btn2_value - 1
                });
                db.collection("products").doc(this.props.product.id).set({ btn0: btn1_value + 1, btn1: btn2_value - 1 }, { merge: true });
                db.collection("users").doc(this.props.uid).collection("user_prods_interactions").doc(this.props.product.id).set({ status_btn: 'btnb' }, { merge: true });
            }
        }
    };
    
    // Operações ao se clicar no segundo botão:
    // 1 - Verifica se está logado, se não estiver manda um aviso
    // 2 - Estando logado:
    // 3 - Atualiza o botão e salva essa atualização no banco de dados

    handleClick_soso = () => { 
        if(this.props.uid===null){
            this.setState({ opendial: true, opendialmsg: 'Você precisa estar logado para isso.' });
        } else {
            
            var db = firebase.firestore(); // Connecting to Firestore
            var btn1_value = this.state.btn1_value;
            var btn2_value = this.state.btn2_value;
            
            if((this.state.btn_pressed1===false) && ((this.state.btn_pressed2===false))){
                this.setState({ btn_pressed2: true, btn2_value: btn2_value + 1  });
                db.collection("products").doc(this.props.product.id).set({ btn1: btn2_value + 1 }, { merge: true });
                db.collection("users").doc(this.props.uid).collection("user_prods_interactions").doc(this.props.product.id).set({ status_btn: 'btnc' }, { merge: true });
            }
            if((this.state.btn_pressed1===false) && ((this.state.btn_pressed2===true))){
                this.setState({ btn_pressed2: false, btn2_value: btn2_value - 1 });
                db.collection("products").doc(this.props.product.id).set({ btn1: btn2_value - 1 }, { merge: true });
                db.collection("users").doc(this.props.uid).collection("user_prods_interactions").doc(this.props.product.id).set({ status_btn: 'btna' }, { merge: true });
            }
            if((this.state.btn_pressed1===true) && ((this.state.btn_pressed2===false))){
                this.setState({ 
                    btn_pressed1: false,
                    btn_pressed2: true,
                    btn1_value: btn1_value - 1,
                    btn2_value: btn2_value + 1,
                });
                db.collection("products").doc(this.props.product.id).set({ btn0: btn1_value - 1, btn1: btn2_value + 1 }, { merge: true });
                db.collection("users").doc(this.props.uid).collection("user_prods_interactions").doc(this.props.product.id).set({ status_btn: 'btnc' }, { merge: true });
            }
        }
    };

    handleClick_comm = () => {
        if(this.props.uid===null){
            this.setState({ opendial: true, opendialmsg: 'Você precisa estar logado para isso.' });
        } else {
            this.setState({ open: true, commenting: !this.state.commenting});
        }
    };

    handleCloseDial = () => {
        this.setState({ opendial: false, opendialmsg: '' });
    };

    handleSavecomm(){
        var db = firebase.firestore(); // Connecting to Firestore

        const today = new Date();
        db.collection("products").doc(this.props.product.id).set({ btn3: this.state.btn3_value + 1 }, { merge: true });
        db.collection("products").doc(this.props.product.id).collection("comments").add({
            comment: this.state.comment,
            user_uid: this.props.uid,
            creation_time: today,
        })
        db.collection("users").doc(this.props.uid).collection("user_prods_interactions").doc(this.props.product.id).set({ comments: true }, { merge: true });
        this.setState({ 
            open: false, 
            commenting: !this.state.commenting, 
            opendial: true, 
            opendialmsg: 'O comentário foi inserido',
            btn3_value: this.state.btn3_value + 1,
            comments: true,
        });
        
        var comments_vector = this.state.comments_vector;
        comments_vector[comments_vector.length] = { comment: this.state.comment, user_uid: this.props.uid, creation_time: today, just_added: true };
        this.setState({ comments_vector: comments_vector, comment: '', commenting: !this.state.commenting });
    }

    render(){
    const { classes } = this.props;
    var card_type, card_title, card_description, description, title, product_img, type, value, btn1, btn2, btn3;
        
    if(this.props.product !== undefined){
        product_img = this.props.product.img_storage;
        title = this.props.product.name_prod;
        card_title = 'card_title';
        description = this.props.product.description;
        card_description ='card_description';
        type = "Tipo: " + this.props.product.type_name;
        card_type = 'card_type';
        (this.props.product.value!=='') ? value = "Valor estimado: R$" + this.props.product.value : value = '';
        btn1 = 'take';
        btn2 = 'soso';
        btn3 = 'comm';
    }
    
    return (
                
        <div>

            <Card className={classes.card}>
            <Grid container spacing={8}>
                <Grid onClick={this.handleOpen} item xs={12}>
                    <CardMedia image={product_img} title="Imagem do produto" className={classes.media} src="img" />
                </Grid>
                <Grid item xs={12}>
                    <CardContent className={classes.card_content}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} onClick={this.handleOpen} className={classes.card_title}>
                                <Title title={title} titlecss={card_title}/>
                            </Grid>
        
                            <Grid item xs={12} onClick={this.handleOpen} className={classes.card_description}>
                                <Title title={description} titlecss={card_description}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs={6} onClick={this.handleOpen} className={classes.card_type}>
                                <Title title={type} titlecss={card_type}/>
                            </Grid>
                            <Grid item xs={6} onClick={this.handleOpen} className={classes.card_type}>
                                <Title title={value} titlecss={card_type}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0}>    
                            <Grid item xs={4} className={classes.card_btn}>
                                <Divider  variant="middle" className={classes.card_divider}/>
                                <BarBtn btn_type={btn1} onPress_btn={this.handleClick_take} btn_pressed={this.state.btn_pressed1} btn_value={this.state.btn1_value} />
                            </Grid>
                            <Grid item xs={4} className={classes.card_btn}>
                                <Divider  variant="middle" className={classes.card_divider}/>
                                <BarBtn btn_type={btn2} onPress_btn={this.handleClick_soso} btn_pressed={this.state.btn_pressed2} btn_value={this.state.btn2_value}/>
                            </Grid>
                            <Grid item xs={4} className={classes.card_btn}>
                                <Divider  variant="middle" className={classes.card_divider}/>
                                <BarBtn btn_type={btn3} onPress_btn={this.handleClick_comm} btn_pressed={this.state.comments} btn_value={this.state.btn3_value}/>
                            </Grid>
        
                        </Grid>
                    </CardContent>
                </Grid>
            </Grid>
            </Card>
            <Dial opendial={this.state.opendial} onPressedcl={this.handleCloseDial} opendialmsg={this.state.opendialmsg} />

            <ModalWrapped 
                product_img={product_img} title={title} card_title={card_title} 
                description={description} card_description={card_description} 
                value={value} type={type} card_type={card_type} 
                onChange_comm={this.handleCommchange} onPressed_savecomm={this.handleSavecomm}
                onPressed_take={this.handleClick_take} onPressed_soso={this.handleClick_soso} onPressed_comm={this.handleClick_comm}
                commenting={this.state.commenting} chars_left={this.state.chars_left}
                comment_written={this.state.comment}
                btn_pressed1={this.state.btn_pressed1} btn_pressed2={this.state.btn_pressed2} comments={this.state.comments}
                btn1_value={this.state.btn1_value} btn2_value={this.state.btn2_value} btn3_value={this.state.btn3_value}
                btn1={btn1} btn2={btn2} btn3={btn3}
                comments_vector={this.state.comments_vector}
                open={this.state.open} onClose2={this.handleClose}/>
        </div>
        
    );
    }
}

Cards.propTypes = {
  classes: PropTypes.object.isRequired,
};

// Colocação do estilo no componente
export default withStyles(styles)(Cards);