import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    textdial: {
        fontSize: 14,
    },  
};

class Dial extends React.Component {
    render() {
        const { onPressedcl, classes } = this.props;
        return (
            <div>
                <Dialog  open={this.props.opendial} onClose={onPressedcl} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                    <DialogTitle id="alert-dialog-title">{"Wontgivup"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" className={classes.textdial}>
                                {this.props.opendialmsg}
                            </DialogContentText>
                        </DialogContent>
                    <DialogActions>
                        <Button onClick={onPressedcl}>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

Dial.propTypes = {
  classes: PropTypes.object.isRequired,
};

// Colocação do estilo no componente
export default withStyles(styles)(Dial);