import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Image from '../../media/paper-2221812_1920.jpg'

// Criação do estilo
const styles = {
    root:{
        padding: 10,
        backgroundImage: `url(${Image})`,
        height: 360,
        backgroundSize: 'cover',
    },
    media:{
        margin: 'auto',
    },
    text1:{
        color: grey[800],
        paddingTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
         fontWeight: 800,
        //paddingLeft: '10%',
    },
    text2:{
        color: grey[800],
        paddingTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
        fontWeight: 800,
        //paddingLeft: '10%',
    },
    text3:{
        color: grey[800],
        paddingTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
        fontWeight: 800,
        //paddingLeft: '10%',
        paddingBottom: 21,
    },
    paper:{
        marginTop: 60,
        margin: 'auto',
        maxWidth: 300,
        minWidth: 200,
    },
};

function HomeImg (props) {   
    const { classes } = props;

    return (
        <div className={classes.root} >
            <Paper className={classes.paper} elevation={20}>
                <Typography variant="subtitle1" className={classes.text1}>É bem simples:</Typography>
                <Typography variant="subtitle1" className={classes.text1}>1 - Poste aqui produtos que você quer, mas não encontra (talvez alguém dê uma dica)</Typography>
                <Typography variant="subtitle1" className={classes.text2}>Ou então:</Typography>
                <Typography variant="subtitle1" className={classes.text3}>2 -  Olhe produtos interessantes e dê A dica!</Typography>
            </Paper>
        </div>
    );
}

HomeImg.propTypes = {
  classes: PropTypes.object.isRequired,
};

// Colocação do estilo no componente
export default withStyles(styles)(HomeImg);