import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import green from '@material-ui/core/colors/green';
import CircularProgress from '@material-ui/core/CircularProgress';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';

import BarBtn from '../../1atoms/button/button.js';
import Title from '../../1atoms/title/title.js';
import LogoImg from '../../media/escrito_fb.jpeg';


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
    paper: {
        position: 'absolute',
        maxWidth: 320,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: 0,
        maxHeight: '85vh',
        overflowY:'auto',
    },  
  cssLabel: {
    '&$cssFocused': {
      color: green[500],
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: green[500],
    },
  },
    
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: green[500],
    },
  },
  notchedOutline: {},
    title:{
        fontSize: 28,
        padding: 5,
    },
    img:{
        margin: 'auto',
        display: 'block',
        width: '100%',
    },
    card_btn:{
    textAlign: 'center',
    paddingTop: 5,
    paddingBottom: 10,
  },
    comments:{
        paddingBottom:5,
        paddingTop: 10,
    },
    comments2a:{
        paddingTop: 0,
        paddingBottom: 0,
        textAlign: 'center',
    },
    comments2b:{
        paddingTop: 0,
        paddingBottom: 10,
        textAlign: 'right',
        paddingRight: 4,
    },
    comments2c:{
        marginTop: 0,
        marginBottom: 10,
    },
    comments2d:{
        paddingTop: 0,
        paddingBottom: 0,
        textAlign: 'center',
        paddingLeft: 5,
        paddingRight: 5,
    },
    input_file:{
        fontSize: 12,
    },
    progress2:{
        color: green[700],
    },
    progressback2:{
        height: '40vh',
        top: 'inherit',
        left: 'inherit',
        position: 'absolute',
    },
});

class ModalProduct extends React.Component {

  render() {
    const {onPressed_saveprod, onChange_img, onChange_title, onChange_type, onChange_description, onChange_value, onClose2, classes } = this.props;
    var types_product = [];
    var open_modal = false;
    if (this.props.open){ open_modal = true };
    if (this.props.types_product!==undefined){ 
        for(var i=0; i<this.props.types_product.length  ; i++){
            types_product.push(
                          <MenuItem key={i+1} value={i+1}>
                            {this.props.types_product[i].type}
                          </MenuItem>
                        );
        }
    }
      var btn_name= 'Salvar produto';
      var img_showed = LogoImg;
      var circular_showing = false;
      var circular_showing2 = undefined;
      if(this.props.img_file!==''){ img_showed = this.props.img_file && URL.createObjectURL(this.props.img_file); }
      if( this.props.circular === 'rodando' ){
        circular_showing = true;
        circular_showing2 = true;
      }
      
      return (
      <div>
        <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open_modal} onClose={onClose2} >
          <div style={getModalStyle()} className={classes.paper}>
{ (this.props.circular !== '') &&
                <div className={classes.progressback2}>    
                    <CircularProgress className={classes.progress2} />
                </div>       
}
                <img  src={img_showed} className={classes.img} alt="Imagem do produto"/>
                <Grid container spacing={0}>
                    <Grid item xs={12} className={classes.title}>
                        <Input type="file" onChange={onChange_img} disableUnderline={true} disabled={circular_showing} />
                        <Title title="Tamanho máx. 2MB" titlecss="helper_input"/>
                    </Grid>
                    <Grid item xs={12} className={classes.title}>
                          <TextField
                            onChange={onChange_title} multiline rows="2" value={this.props.title}
                            helperText={this.props.chars_left_title + " caracteres restantes"} disabled={circular_showing}
                            InputLabelProps={{ classes: { root: classes.cssLabel, focused: classes.cssFocused,},}}
                            InputProps={{ classes: { root: classes.cssOutlinedInput, focused: classes.cssFocused, notchedOutline: classes.notchedOutline, },}} fullWidth  label="Título do produto" variant="outlined" id="comments-outlined-input" required
                          />
                    </Grid>
                    <Grid item xs={12} className={classes.title}>
                        <TextField
                            onChange={onChange_description} multiline rows="3" value={this.props.description}
                            helperText={this.props.chars_left_desc + " caracteres restantes"} disabled={circular_showing}
                            InputLabelProps={{ classes: { root: classes.cssLabel, focused: classes.cssFocused,},}}
                            InputProps={{ classes: { root: classes.cssOutlinedInput, focused: classes.cssFocused, notchedOutline: classes.notchedOutline, },}} fullWidth  label="Descrição do produto" variant="outlined" id="comments-outlined-input" required
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.title}>
                        <TextField
                        id="outlined-select-product-type" select label="Tipo de produto:"
                        value={this.props.type_prod} onChange={onChange_type} disabled={circular_showing}
                        SelectProps={{ MenuProps: { className: classes.menu,},}}
                        InputLabelProps={{ classes: { root: classes.cssLabel, focused: classes.cssFocused,},}}
                        InputProps={{ classes: { root: classes.cssOutlinedInput, focused: classes.cssFocused, notchedOutline: classes.notchedOutline, },}} 
                        fullWidth={true} margin="normal" variant="outlined">
                            {types_product}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} className={classes.title}>
                        <TextField
                            onChange={onChange_value} type="number" value={this.props.value_prod}
                            helperText={this.props.chars_left_value + " caracteres restantes"} disabled={circular_showing}
                            InputLabelProps={{ classes: { root: classes.cssLabel, focused: classes.cssFocused,},}}
                            InputProps={{ classes: { root: classes.cssOutlinedInput, focused: classes.cssFocused, notchedOutline: classes.notchedOutline, },}} fullWidth  label="Valor estimado do produto" variant="outlined" id="outlined-number"
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.comments2b}>
                        <BarBtn btn_name={btn_name} onPress_btn={onPressed_saveprod} disabled_btn={circular_showing2}/>
                    </Grid>
 
                </Grid>
            <ModalWrapped />
          </div>
        </Modal>
      </div>
    );
  }
}

ModalProduct.propTypes = {
  classes: PropTypes.object.isRequired,
};

// We need an intermediary variable for handling the recursive nesting.
const ModalWrapped = withStyles(styles)(ModalProduct);

export default ModalWrapped;