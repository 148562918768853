import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

// Criação do estilo
const styles = {
    root:{
        backgroundColor: grey[50],
    },
  media: {
        paddingTop: 5,
        paddingBottom: 5,
//        fontWeight: '700',
        maxWidth: '700px',
        textAlign: 'center',
        fontSize: 14,
        backgroundColor: grey[50],
        color: green[700],
  },
};

function FootTxt (props) {   
    const { classes } = props;
    return (
            <div className={classes.root}>
                <Grid container spacing={0} >
                    <Grid item xs={12} >
                        <Grid container justify="center">
                            <div className={classes.media}>
                                <Typography variant="h5" color="inherit" className={classes.media}>
                                    Alguma dúvida? Fale com: joaoricardofl@gmail.com
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
    );
}

FootTxt.propTypes = {
  classes: PropTypes.object.isRequired,
};

// Colocação do estilo no componente
export default withStyles(styles)(FootTxt);