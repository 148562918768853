import React, { Component } from 'react';
import * as firebase from 'firebase';
import './App.css';

import HomePage from './components/5enviroments/home_page/home_page.js'

class App extends Component {
    
    constructor(){
        super();
        this.state={
            uid: null,
        };
    }

    UNSAFE_componentWillMount(){
        
        // Initialize Firebase
        var config = {
            apiKey: "AIzaSyAAJfbPYqpLEoR9PS7gMBlbDcloNs0OzgE",
            authDomain: "proj6-df49a.firebaseapp.com",
            databaseURL: "https://proj6-df49a.firebaseio.com",
            projectId: "proj6-df49a",
            storageBucket: "proj6-df49a.appspot.com",
            messagingSenderId: "497575299643"
        };
      firebase.initializeApp(config);
        
        !firebase.apps.length ? firebase.initializeApp(config).firestore().settings({ timestampsInSnapshots: true }) : firebase.app().firestore().settings({ timestampsInSnapshots: true });

        firebase.auth().useDeviceLanguage();
        
        firebase.auth().onAuthStateChanged(firebaseUser=>{
            if(firebaseUser){
                this.setState({ uid: firebaseUser.uid });
            } else {
                this.setState({ uid: null });
            };
        });
    }
    
    
  render() {
    return (
      <div>
{(this.state.uid===null) &&
        <HomePage uid={this.state.uid}/>
}
        
{(this.state.uid!==null) &&
        <HomePage uid={this.state.uid}/>
}
      </div>
    );
  }
}

export default App;
