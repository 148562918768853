import * as firebase from 'firebase';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';

import Footer from '../../1atoms/footer/footer.js';
import Bar from '../../4ecosystems/bar/bar.js'
import HomeImg from '../../4ecosystems/home_img/home_img.js'
import ListCards from '../../4ecosystems/list_cards/list_cards.js'
import CircularProgress from '@material-ui/core/CircularProgress';
import Dial from '../../4ecosystems/dialog/dialog.js';
import ModalWrapped from '../../4ecosystems/modal_insert_product/modal_insert_product.js';

// Criação do estilo
const styles = {
    back:{
        backgroundColor: '#eeeeee',
    },
    progress:{
        position: 'relative',
        left: '45%',
        top: '43%',
        color: green[700],
    },
    progressback:{
        height: '40vh',
        display: 'relative',
    },
};

class HomePage extends Component {

    constructor(){
        super();
        this.state={
            logged: false, 
            notVerified: false,
            products: '',
            user_prod_interactions: '',
            open: false,
            opendial: false,
            opendialmsg: '',
            chars_left_title: 60,
            chars_left_desc: 250,
            title: '',
            description: '',
            chars_left_value: 7,
            value: '',
            types_product: '',
            type_prod: 15,
            selectedFile: '',
            circular: '',
        };
        this.handleSaveProd=this.handleSaveProd.bind(this);
    }
    
    async componentWillMount(){// Procura no banco os dados já preenchidos
        
        var db = firebase.firestore(); // Connecting to Firestore        
        var products = [];
        var user_prod_interactions = [];
        products = await db.collection("products").orderBy("btn0","desc").get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    products.push(doc.data());
                    products[products.length - 1].id = doc.id;
                });
                return products;
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
        });
        
        var types_product = [];
        types_product = await db.collection("types_prod").get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    types_product.push(doc.data());
                    types_product[types_product.length - 1].id = doc.id;
                });
                return types_product;
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
        });
        
        if (this.props.uid !== null){
        
            user_prod_interactions = await db.collection("users").doc(this.props.uid).collection("user_prods_interactions").get()
                .then(function(querySnapshot) {
                    querySnapshot.forEach(function(doc) {
                        user_prod_interactions.push(doc.data());
                        user_prod_interactions[user_prod_interactions.length - 1].id = doc.id;
                    });
                    return user_prod_interactions;
                })
                .catch(function(error) {
                    console.log("Error getting documents: ", error);
            });
            this.setState({ products: products , types_product: types_product, user_prod_interactions: user_prod_interactions });
        } else { 
            this.setState({ products: products }); 
        }
    }    

    handleCloseDial = () => {
        this.setState({ opendial: false, opendialmsg: '' });
    };

    handleOpenmodal = () => { 
        if(this.props.uid===null){
            this.setState({ opendial: true, opendialmsg: 'Você precisa estar logado para isso.' });
        } else {
            this.setState({ open: true });
        }  
    };

    handleClose = () => { this.setState({ open: false });     };

    handleTitlechange = (event) => { 
        const charCount = event.target.value.length;
        const charLeft = 60 - charCount;
        if (charLeft >= 0) { this.setState({ title: event.target.value, chars_left_title: charLeft }); }
    }

    handleDescripchange = (event) => { 
        const charCount = event.target.value.length;
        const charLeft = 250 - charCount;
        if (charLeft >= 0) { this.setState({ description: event.target.value, chars_left_desc: charLeft }); }
    }
    
    handleValuechange = (event) => { 
        const charCount = event.target.value.length;
        const charLeft = 7 - charCount;
        if (charLeft >= 0) { this.setState({ value: event.target.value, chars_left_value: charLeft }); }
    }
    
    handleChangeType = (event) => { 
        this.setState({ type_prod: event.target.value });
    }
    
    handleImgchange = (event) => {
        const file = event.target.files[0];
        if (file!==undefined){
            if (file.size>(2*1024*1024)){
                this.setState({ opendial: true, opendialmsg: 'Essa imagem excede o limite de tamanho.'});
            } else {
                this.setState({selectedFile: event.target.files[0]});
            }
        }
    }
    
    async handleSaveProd(){

        if( (this.state.title==='') || (this.state.description==='')){
            this.setState({ opendial: true, opendialmsg: 'É necessário preencher o título e a descrição do produto.' });
        } else {
            
            this.setState({ circular: 'rodando' });
            
            var db = firebase.firestore(); 
            var curentTime = new Date();
            
            if(this.state.selectedFile === ''){
                
                await db.collection("products").add({ 
                    name_prod: this.state.title,
                    description: this.state.description,
                    value: this.state.value,
                    user_id: this.props.uid,
                    img_storage: 'https://firebasestorage.googleapis.com/v0/b/proj6-df49a.appspot.com/o/escrito_fb.jpeg?alt=media&token=d1125e6d-1fd1-43d2-b408-cc0a90681906',
                    deleted: false,
                    img_name: '',
                    creation_time: curentTime,
                    type: this.state.types_product[this.state.type_prod - 1].type,
                    btn0: 0,
                    btn1: 0,
                    btn2: 0,
                    btn3: 0,
                });
            } else {
                
                var storageRef = firebase.storage().ref();
                
                const img_name = "img_prod/2".concat("_", curentTime.getYear(), "_", curentTime.getMonth(), "_", curentTime.getDate(), "_", curentTime.getHours(), "_", curentTime.getMinutes(), "_", curentTime.getSeconds());
                
                let url = await storageRef.child(img_name).put(this.state.selectedFile).then(function(snapshot) {
                    console.log('Uploaded a blob or file!');
                    var url = storageRef.child(img_name).getDownloadURL().then(function(url) {
                        // `url` is the download URL for 'images/stars.jpg'
                        var xhr = new XMLHttpRequest();
                        xhr.responseType = 'blob';
                        xhr.open('GET', url);
                        xhr.send();
                        return url;
                    });
                    return url;
                });
                
                await db.collection("products").add({ 
                    name_prod: this.state.title,
                    description: this.state.description,
                    value: this.state.value,
                    user_id: this.props.uid,
                    img_storage: url,
                    deleted: false,
                    img_name: '',
                    creation_time: curentTime,
                    type: this.state.types_product[this.state.type_prod - 1].type,
                    btn0: 0,
                    btn1: 0,
                    btn2: 0,
                    btn3: 0,
                });
                
            }
            
            this.setState({ open: false, opendial: true, opendialmsg: 'O produto foi salvo.', circular: '' });
            
        }
    }
    
    render(){
        const { classes} = this.props;
        return (
            <div className={classes.back}>
                <Bar uid={this.props.uid}/>
                <HomeImg/>
{ (this.state.products !== '') &&
                <ListCards uid={this.props.uid} products={this.state.products} user_prod_interactions={this.state.user_prod_interactions} onPress_sup={this.handleOpenmodal}/>                
}
{ (this.state.products === '') &&
                <div className={classes.progressback}>    
                    <CircularProgress className={classes.progress} />
                </div>       
}
                <Dial opendial={this.state.opendial} onPressedcl={this.handleCloseDial} opendialmsg={this.state.opendialmsg} />
                <ModalWrapped 
                    onChange_img={this.handleImgchange} img_file={this.state.selectedFile}
                    onChange_title={this.handleTitlechange} chars_left_title={this.state.chars_left_title} title={this.state.title}
                    onChange_description={this.handleDescripchange} chars_left_desc={this.state.chars_left_desc} description={this.state.description}
                    onChange_value={this.handleValuechange} chars_left_value={this.state.chars_left_value} value_prod={this.state.value}
                    onPressed_saveprod={this.handleSaveProd}
                    chars_left={this.state.chars_left}
                    circular={this.state.circular}
                    types_product={this.state.types_product}
                    onChange_type={this.handleChangeType} type_prod={this.state.type_prod}
                    open={this.state.open} onClose2={this.handleClose}/>

                <Footer/>
            </div>
        );
    }
}

HomePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

// Colocação do estilo no componente
export default withStyles(styles)(HomePage);